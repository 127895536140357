import { Report, VisualDescriptor } from 'powerbi-client';
import { IBasicFilter } from 'powerbi-models';

export class PowerBiHelper {
  public static async getSlicersValues(visuals: VisualDescriptor[]): Promise<string[][]> {
    return await Promise.all(visuals.map((v) => PowerBiHelper.getSlicerValues(v)));
  }

  private static async getSlicerValues(visual: VisualDescriptor): Promise<string[]> {
    const slicerState = await visual.getSlicerState();

    // No values selected
    if (slicerState.filters.length === 0) {
      return [];
    }

    const basicFilter = slicerState.filters[0] as IBasicFilter;

    return basicFilter.values.map((v) => v.toString());
  }

  public static async setSlicersValues(visuals: VisualDescriptor[], values: string[][]): Promise<void> {
    await Promise.all(visuals.map((v, i) => PowerBiHelper.setSlicerValues(v, values[i])));
  }

  private static async setSlicerValues(visual: VisualDescriptor, values: string[]): Promise<void> {
    const slicerState = await visual.getSlicerState();

    if (values.length > 0) {
      // Initialize the filters array if it doesn't exist
      slicerState.filters = [
        {
          $schema: 'http://powerbi.com/product/schema#basic',
          target: slicerState.targets?.at(0),
          filterType: 1,
          operator: 'In',
          values,
        },
      ] as IBasicFilter[];
    } else {
      slicerState.filters = [];
    }

    if (!visual.setSlicerState) {
      console.log('setSlicerState not found', visual, values);
    }

    await visual.setSlicerState(slicerState);
  }

  public static async determineControls(
    report: Report,
    controlTitles: string[]
  ): Promise<(VisualDescriptor | undefined)[]> {
    const page = await report.getActivePage();
    const visuals = await page.getVisuals();

    return controlTitles.map((title) => visuals.find((v) => v.title === title));
  }
}
