import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { getTranslation } from '../../../utils/getTranslation';
import FullSizeLoader from '../Loader/FullSizeLoader';
import InputWithInfo from '../../../features/PetForm/InputWithInfo';
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  titleText: string;
  valueLabel: string;
  actionButtonText: string;
  isLoading?: boolean;
  dataTestId?: string;
  validationRegex?: RegExp;
}

const StringInputModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  titleText,
  valueLabel,
  actionButtonText,
  isLoading,
  dataTestId,
  validationRegex,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: { value: '' },
  });

  const onCancelRequested = () => {
    reset();
    onClose();
  };

  const onConfirmRequested = () => {
    onConfirm(getValues().value);
    reset();
  };

  return (
    <Modal
      open={isOpen}
      size="small"
      className="warningModal"
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      onClose={() => !isLoading && onCancelRequested()}
      data-testid={dataTestId}
    >
      <Modal.Header>
        <h3>{titleText}</h3>
      </Modal.Header>
      <Modal.Content data-testid="modal-content">
        <Form>
          <InputWithInfo
            required
            label={valueLabel}
            error={errors.value}
            register={register('value', {
              required: getTranslation('FieldRequired'),
              pattern: validationRegex && {
                value: validationRegex,
                message: getTranslation('Invalid_Value_Message'),
              },
            })}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="cancel-btn"
          className="form-button"
          basic
          size="small"
          color="blue"
          onClick={onCancelRequested}
        >
          {getTranslation('Cancel')}
        </Button>
        <Button
          data-testid="submit-btn"
          className="form-button"
          size="small"
          color={'blue'}
          onClick={handleSubmit(onConfirmRequested)}
        >
          {actionButtonText}
        </Button>
      </Modal.Actions>

      {isLoading && <FullSizeLoader />}
    </Modal>
  );
};

export default StringInputModal;
