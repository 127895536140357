import React from 'react';
import { ReportIcon, ReportType } from './../../../api/graphql/models/ReportsResponse';
import { ReportTypeApiDto, ReportTypeDto } from './../../../api/graphql/models/ReportTypeDto';
import { ReportDetailsDto } from './../../../api/rest/models/EmbedTokenResponseAggregate/ReportDetailsDto';
import { DashboardReportViewModel } from './../../../models/ReportsAggregate/Report';
import { ReportPaneInterface } from './../../../shared/store/reportsPageContext';

export const mockedReportTypeDto: ReportTypeDto = {
  _id: 0,
  displayName: '',
  reportName: '',
  isPaginated: false,
  icon: ReportIcon.ChartLine,
  reportType: ReportType.Analytical,
  description: '',
};

export const mockedReportTypeApiDto: ReportTypeApiDto = {
  id: 0,
  displayName: '',
  reportName: '',
  isPaginated: false,
  icon: ReportIcon.ChartLine,
  reportType: ReportType.Analytical,
  description: '',
};

export const mockedReportDetailsDTO: ReportDetailsDto[] = [
  {
    id: 0,
    reportName: 'test',
    displayName: 'test',
    embedUrl: 'test',
    datasetId: 'test',
    powerBiId: 'test',
    description: 'description',
    icon: ReportIcon.Book,
    reportType: ReportType.Action,
  },
  {
    id: 1,
    reportName: 'test1',
    displayName: 'test1',
    embedUrl: 'test1',
    datasetId: 'test1',
    powerBiId: 'test1',
    description: 'description',
    icon: ReportIcon.Book,
    reportType: ReportType.Action,
  },
];

export const mockedReportDetailsViewModel: DashboardReportViewModel = {
  id: 0,
  icon: ReportIcon.ChartLine,
  displayName: '',
  reportName: '',
  reportType: ReportType.Analytical,
  description: '',
};

export const MOCKED_REPORT: { [key: string]: string } = {
  Report1: 'report1',
  Report2: 'report2',
  Report3: 'report3',
};

export const mockedDefaultReportOrder = 5;
export const mockedDefaultReportIcon = 'testIcon';

const mockedMenuItem = () => <></>;
const mockedRender = () => <></>;
mockedMenuItem.displayName = 'mockedMenuItem1';
mockedRender.displayName = 'mockedRender1';
export const mockedPane: ReportPaneInterface = {
  menuItem: mockedMenuItem,
  render: mockedRender,
  displayName: '',
  id: 0,
};
