export enum ReportAccessType {
  None = 0,
  Read = 1,
  ReadExport = 2,
  ReadWriteExport = 3,
}

export const hasAnyAccess = (accessType: ReportAccessType | null | undefined): boolean => {
  if (!accessType) {
    return false;
  }

  return [ReportAccessType.Read, ReportAccessType.ReadExport, ReportAccessType.ReadWriteExport].includes(
    accessType
  );
};
