/* istanbul ignore file */

import React from 'react';
import { ActionResult, ReportConfigurationModel, TypedActionResult } from './types';

const notImplementedError = new Error('Not implemented');

export interface DynamicContextProps {
  get(): Promise<TypedActionResult<ReportConfigurationModel[]>>;
  create: (name: string, fields: string[], gcns: string[], smids: string[]) => Promise<ActionResult>;
  update: (fields: string[], gcns: string[], smids: string[]) => Promise<ActionResult>;
  rename: (value: string) => Promise<ActionResult>;
  del: () => Promise<ActionResult>;
  getAllReportConfigurations: () => ReportConfigurationModel[];
  getActiveReportConfiguration: () => ReportConfigurationModel | null;
  setActiveReportConfiguration: (id: string) => void;
}

const defaultDynamicContextPropsValue = {
  get: () => Promise.reject(notImplementedError),
  create: () => Promise.reject(notImplementedError),
  update: () => Promise.reject(notImplementedError),
  rename: () => Promise.reject(notImplementedError),
  del: () => Promise.reject(notImplementedError),
  getAllReportConfigurations: () => [],
  getActiveReportConfiguration: () => null,
  setActiveReportConfiguration: () => {
    throw notImplementedError;
  },
};

const dynamicReportContext = React.createContext<DynamicContextProps>(defaultDynamicContextPropsValue);

export { dynamicReportContext };
