import React, { useContext, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { defaultReportsContextValue, ReportsContextProps, reportsContext } from '../store/reportsContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ReportTypesService } from '../../services/ReportTypesService';
import { mapReportsFromDTO } from '../../features/AdministrationContainer/Reports/reportsMapper';
import { ReportNameViewModel, ReportViewModel } from '../../models/ReportsAggregate/Report';
import { ReportNameDTO } from '../../api/rest/models/Reports';
import { ReportsService } from '../../services/ReportsService';
import { userContext } from '../store/userContext';
import { RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { ReportTypeApiDto } from '../../api/graphql/models/ReportTypeDto';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const Reports: React.FC<Props> = ({ children, client }) => {
  const [reportsData, setReportsData] = useState<ReportViewModel[]>([]);
  const [requestReportsStatus, setRequestReportsStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );
  const { workSpaceId, environment, version } = useContext(userContext);
  const [reportsNamesData, setReportsNamesData] = useState<ReportNameViewModel[]>([]);
  const [requestReportsNamesStatus, setRequestReportsNamesStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );
  const reportRestAPIService = new ReportsService(workSpaceId, environment, version);

  const getReports = async (isPaginated?: boolean) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const reportTypesService: ReportTypesService = new ReportTypesService(clientApollo);
      setRequestReportsStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await reportTypesService.getReportTypes(isPaginated);
      const reports = (response as RestApiResponse<ReportTypeApiDto[]>).result ?? [];

      setRequestReportsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setReportsData(mapReportsFromDTO(reports));
    } catch (error) {
      console.error(error);
      setRequestReportsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getReportsNames = async () => {
    try {
      setRequestReportsNamesStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await reportRestAPIService.getAllReportNames();
      const successResponse = response as RestApiResponse<ReportNameDTO[]>;

      setRequestReportsNamesStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setReportsNamesData(successResponse.result);
    } catch (error) {
      console.error(error);
      setRequestReportsNamesStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const resetCachedReportsNames = () => {
    reportRestAPIService.resetCache();
  };

  const getReportsContext = (): ReportsContextProps => {
    return {
      ...defaultReportsContextValue,
      getReports,
      reportsData,
      requestReportsStatus,
      getReportsNames,
      resetCachedReportsNames,
      reportsNamesData,
      requestReportsNamesStatus,
    };
  };

  return <reportsContext.Provider value={getReportsContext()}>{children}</reportsContext.Provider>;
};

const WithReports = WithApolloClient(Reports);

export { WithReports };
