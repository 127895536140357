/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React from 'react';
import BasicReport, { BasicReportProps, BasicReportState } from '../BasicReport';
import { EventHandler } from 'powerbi-client-react';
import { Report, VisualDescriptor } from 'powerbi-client';
import StringInputModal from '../../../shared/view/Modal/StringInputModal';
import { Button, Grid, Message } from 'semantic-ui-react';
import { getTranslation } from '../../../utils/getTranslation';
import { dynamicReportContext } from './dynamicReportContext';
import './styles.scss';
import Sidebar from '../../../shared/view/Sidebar/Sidebar';
import { ActionResult, ActionType, ReportConfigurationModel } from './types';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import TextButton from '../../../shared/controls/TextButton';
import GroupableDropdown from '../../../shared/controls/GroupableDropdown/GroupableDropdown';
import { PowerBiHelper } from '../../../utils/powerBiHelper';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { Location } from 'history';
import { haveSameElements } from '../../../utils/arrayHelper';
import WarningModal from '../../WarningModal';

export interface DynamicReportState extends BasicReportState {
  isSaveDialogOpen: boolean;
  isSaveDialogLoading: boolean;
  isMessageVisible: boolean;
  isMessageSuccess: boolean;
  messageText: string;
  areReportConfigurationsLoading: boolean;
  isPowerBiLoading: boolean;
  isPageLoadingFailed: boolean;
  isReportConfigurationApplying: boolean;
  isUnsavedChangesDialogOpen: boolean;
  looseUnsavedChangesCallback: (isAllowed: boolean) => void;
  isDefaultConfigurationApplyingFinished: boolean;
  isDeleteDialogOpen: boolean;
  isDeleteDialogLoading: boolean;
  isRenameDialogOpen: boolean;
  isRenameDialogLoading: boolean;
}

interface DynamicReportProps extends BasicReportProps, RouteComponentProps {}

class DynamicReport extends BasicReport<DynamicReportProps, DynamicReportState> {
  private static readonly messageTimeout = 5000;
  private static readonly validationRegex = /^[^\s].*$/;
  private static readonly saveButtonName = 'Button Save';
  private static readonly saveAsButtonName = 'Button Save as';
  private static readonly fieldsVisualName = 'Slicer FS';
  private static readonly gcnVisualName = 'Slicer GCN';
  private static readonly smidVisualName = 'Slicer SMID';
  private static readonly visualClickEventName = 'visualClicked';

  private _saveButton?: VisualDescriptor;
  private _saveAsButton?: VisualDescriptor;
  private _fieldsVisual?: VisualDescriptor;
  private _gcnVisual?: VisualDescriptor;
  private _smidVisual?: VisualDescriptor;

  private _defaultConfiguration: ReportConfigurationModel | null;

  private _isPageLeavingAllowed = false;

  private messageTimeoutIdentifier: NodeJS.Timeout | undefined;

  static readonly contextType = dynamicReportContext;
  context!: React.ContextType<typeof dynamicReportContext>;

  constructor(props: DynamicReportProps) {
    super(props);

    this._defaultConfiguration = null;

    this.state = {
      report: undefined,
      isReportFullScreen: false,
      isLoading: this.props.isReportLoaderShown ?? true,
      isSaveDialogOpen: false,
      isSaveDialogLoading: false,
      isMessageVisible: false,
      isMessageSuccess: false,
      messageText: '',
      areReportConfigurationsLoading: false,
      isPowerBiLoading: false,
      isPageLoadingFailed: false,
      isReportConfigurationApplying: false,
      isUnsavedChangesDialogOpen: false,
      looseUnsavedChangesCallback: () => {},
      isDefaultConfigurationApplyingFinished: false,
      isDeleteDialogOpen: false,
      isDeleteDialogLoading: false,
      isRenameDialogOpen: false,
      isRenameDialogLoading: false,
    };
  }

  public componentWillUnmount(): void {
    super.componentWillUnmount?.call(this);

    if (this.messageTimeoutIdentifier) {
      clearTimeout(this.messageTimeoutIdentifier);
    }
  }

  public componentDidMount(): void {
    super.componentDidMount?.call(this);

    this.loadConfigurations();
  }

  public render(): React.ReactNode {
    return (
      <div className="content-container">
        <Prompt when={true} message={this.onNavigationRequested.bind(this)} />
        {!this.state.areReportConfigurationsLoading && this.renderMainContent()}
        {(this.state.areReportConfigurationsLoading ||
          this.state.isPowerBiLoading ||
          this.state.isReportConfigurationApplying) && (
          <div className="loader-container">
            <FullSizeLoader />
          </div>
        )}
      </div>
    );
  }

  private renderMainContent(): React.ReactNode {
    return this.state.isPageLoadingFailed ? (
      <Message error data-testid="errorResponsePetUsers-wrapper">
        <MessageWithControl message={getTranslation('App_LoadingError')}>
          <TextButton
            text={getTranslation('Retry_Now')}
            onClick={this.loadConfigurations.bind(this)}
            dataTestId="errorResponsePetUsers-retry-control"
          />
        </MessageWithControl>
      </Message>
    ) : (
      <Sidebar panel={this.renderSidebar()} title={getTranslation('Dynamic_Report_Sidebar_Title')}>
        <div>
          {super.render()}
          {this.state.isMessageVisible && (
            <div
              className="overlay-message"
              style={{ position: this.state.isReportFullScreen ? 'fixed' : 'absolute' }}
            >
              <Message
                success={this.state.isMessageSuccess}
                error={!this.state.isMessageSuccess}
                content={this.state.messageText}
              />
            </div>
          )}
          <StringInputModal
            isOpen={this.state.isSaveDialogOpen}
            onClose={this.hideSaveDialog.bind(this)}
            onConfirm={this.create.bind(this)}
            titleText={getTranslation('Save_Report_Modal_Title')}
            valueLabel={getTranslation('Name')}
            actionButtonText={getTranslation('Save')}
            isLoading={this.state.isSaveDialogLoading}
            validationRegex={DynamicReport.validationRegex}
          ></StringInputModal>
          <WarningModal
            isOpen={this.state.isUnsavedChangesDialogOpen}
            titleText={getTranslation('Cancel')}
            contentText={getTranslation('CancelWarningContent')}
            onClose={this.onUnsavedChangesDialogClose.bind(this)}
            textActionButton={getTranslation('Yes')}
          />
          <WarningModal
            isOpen={this.state.isDeleteDialogOpen}
            titleText={getTranslation('Delete')}
            contentText={getTranslation('Delete_Report_Configuration_Configuration_Message').replace(
              '{0}',
              this.context.getActiveReportConfiguration()?.name ?? ''
            )}
            onClose={this.onDeleteDialogClose.bind(this)}
            textActionButton={getTranslation('Delete')}
            isLoading={this.state.isDeleteDialogLoading}
          />
          <StringInputModal
            isOpen={this.state.isRenameDialogOpen}
            onClose={() => this.setState({ isRenameDialogOpen: false })}
            onConfirm={this.rename.bind(this)}
            titleText={getTranslation('Rename')}
            valueLabel={getTranslation('Name')}
            actionButtonText={getTranslation('Save')}
            isLoading={this.state.isRenameDialogLoading}
            validationRegex={DynamicReport.validationRegex}
          ></StringInputModal>
        </div>
      </Sidebar>
    );
  }

  private onNavigationRequested(location: Location): boolean {
    if (this._isPageLeavingAllowed) {
      return true;
    }

    this.handlePageLeaving(location.pathname);
    return false;
  }

  private async handlePageLeaving(pathname: string): Promise<void> {
    const hasUnsavedChanges = await this.hasUnsavedChanges();

    if (hasUnsavedChanges) {
      this.setState({
        isUnsavedChangesDialogOpen: true,
        looseUnsavedChangesCallback: (isAllowed: boolean) => {
          this._isPageLeavingAllowed = isAllowed;

          if (isAllowed) {
            this.props.history.push(pathname);
          }
        },
      });
    } else {
      this._isPageLeavingAllowed = true;
      this.props.history.push(pathname);
    }
  }

  private async onDeleteDialogClose(isSuccess: boolean | undefined): Promise<void> {
    const isDeleteConfirmed = isSuccess ?? false;

    if (isDeleteConfirmed) {
      this.setState({ isDeleteDialogLoading: true });

      const activeConfiguration = this.context.getActiveReportConfiguration();

      const result = await this.context.del();
      this.showMessageWithDelayByResult(result, ActionType.Delete, activeConfiguration?.name);
      await this.applyReportConfiguration(this._defaultConfiguration!);
    }

    this.setState({ isDeleteDialogOpen: false, isDeleteDialogLoading: false });
  }

  private onUnsavedChangesDialogClose(isSuccess: boolean | undefined): void {
    this.setState({
      isUnsavedChangesDialogOpen: false,
    });

    const isLoosingChangesAllowed = isSuccess ?? false;

    this.state.looseUnsavedChangesCallback(isLoosingChangesAllowed);
  }

  protected async onReportReady(report: Report): Promise<void> {
    const areCountrolsFoundSuccessfully = await this.determineControlsIds(report);
    this.setState({
      isPowerBiLoading: false,
      isPageLoadingFailed: !areCountrolsFoundSuccessfully,
    });

    if (!areCountrolsFoundSuccessfully) {
      return;
    }

    await this.cacheDefaultConfiguration();

    const activeReportConfiguration = this.context.getActiveReportConfiguration();
    if (activeReportConfiguration) {
      await this.applyReportConfiguration(activeReportConfiguration);
    }

    this.setState({
      isDefaultConfigurationApplyingFinished: true,
    });
  }

  protected buildEventHandlers(): Map<string, EventHandler> {
    const handlers = super.buildEventHandlers();

    handlers.set(
      DynamicReport.visualClickEventName,
      this.onVisualClicked.bind(this) as unknown as EventHandler
    );

    return handlers;
  }

  private renderSidebar(): React.ReactNode {
    const allReportConfigurations = this.context.getAllReportConfigurations();

    const groups = [
      {
        title: '',
        items: allReportConfigurations.map((c) => {
          return { title: c.name, value: c.id };
        }),
      },
    ];

    const activeConfiguration = this.context.getActiveReportConfiguration();

    return (
      <>
        <GroupableDropdown
          groups={groups}
          selectedValue={activeConfiguration?.id}
          onSelectedValueChange={async (id) => {
            const hasUnsavedChanges = await this.hasUnsavedChanges();

            const applySelected = () => {
              this.context.setActiveReportConfiguration(id);

              const activeReportConfiguration = this.context
                .getAllReportConfigurations()
                .find((x) => x.id === id);
              if (activeReportConfiguration) {
                this.applyReportConfiguration(activeReportConfiguration);
              }
            };

            if (hasUnsavedChanges) {
              this.setState({
                isUnsavedChangesDialogOpen: true,
                looseUnsavedChangesCallback: (isAllowed: boolean) => {
                  if (isAllowed) {
                    applySelected();
                  }
                },
              });
            } else {
              applySelected();
            }
          }}
        />
        <Grid columns="equal" padded="vertically">
          <Grid.Row>
            <Grid.Column>
              <Button
                size="small"
                color="blue"
                disabled={!activeConfiguration}
                onClick={this.onDeleteClicked.bind(this)}
              >
                {getTranslation('Delete')}
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                size="small"
                color="blue"
                disabled={!activeConfiguration}
                onClick={this.onRenameClicked.bind(this)}
              >
                {getTranslation('Rename')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }

  private async loadConfigurations(): Promise<void> {
    this.setState({
      report: undefined,
      isPageLoadingFailed: false,
      areReportConfigurationsLoading: true,
      isLoading: true,
    });

    const result = await this.context.get();

    const isSuccess = result.result === ActionResult.Ok;

    this.setState({
      isPageLoadingFailed: !isSuccess,
      areReportConfigurationsLoading: false,
      isPowerBiLoading: isSuccess,
    });
  }

  private async onVisualClicked(e: { detail: { visual: VisualDescriptor } }): Promise<void> {
    if (e.detail.visual.name === this._saveButton?.name) {
      await this.onSaveClicked();
    } else if (e.detail.visual.name === this._saveAsButton?.name) {
      this.onSaveAsClicked();
    }
  }

  private async determineControlsIds(report: Report): Promise<boolean> {
    [this._saveButton, this._saveAsButton, this._fieldsVisual, this._gcnVisual, this._smidVisual] =
      await PowerBiHelper.determineControls(report, [
        DynamicReport.saveButtonName,
        DynamicReport.saveAsButtonName,
        DynamicReport.fieldsVisualName,
        DynamicReport.gcnVisualName,
        DynamicReport.smidVisualName,
      ]);

    return (
      !!this._saveButton &&
      !!this._saveAsButton &&
      !!this._fieldsVisual &&
      !!this._gcnVisual &&
      !!this._smidVisual
    );
  }

  private async getSlicersValues(): Promise<[string[], string[], string[]]> {
    const result = await PowerBiHelper.getSlicersValues([
      this._fieldsVisual!,
      this._gcnVisual!,
      this._smidVisual!,
    ]);

    return result as unknown as [string[], string[], string[]];
  }

  private async applyReportConfiguration(reportConfiguration: ReportConfigurationModel): Promise<void> {
    const { fields, gcns, smids } = reportConfiguration.settings;

    this.setState({
      isReportConfigurationApplying: true,
    });

    await PowerBiHelper.setSlicersValues(
      [this._fieldsVisual!, this._gcnVisual!, this._smidVisual!],
      [fields, gcns, smids]
    );

    this.setState({
      isReportConfigurationApplying: false,
    });
  }

  private async onSaveClicked(): Promise<void> {
    if (!this.context.getActiveReportConfiguration()) {
      this.onSaveAsClicked();
      return;
    }

    const slicersValues = await this.getSlicersValues();

    const result = await this.context.update(...slicersValues);
    this.showMessageWithDelayByResult(result, ActionType.Update);
  }

  private onSaveAsClicked(): void {
    this.setState({
      isSaveDialogOpen: true,
    });
  }

  private onDeleteClicked(): void {
    this.setState({
      isDeleteDialogOpen: true,
    });
  }

  private onRenameClicked(): void {
    this.setState({
      isRenameDialogOpen: true,
    });
  }

  private async create(name: string): Promise<void> {
    this.setState({ isSaveDialogLoading: true });

    const slicersValues = await this.getSlicersValues();

    const result = await this.context.create(name, ...slicersValues);
    this.showMessageWithDelayByResult(result, ActionType.Create, name);

    this.setState({ isSaveDialogLoading: false });
    this.hideSaveDialog();
  }

  private async rename(name: string): Promise<void> {
    this.setState({ isRenameDialogLoading: true });

    const result = await this.context.rename(name);

    this.showMessageWithDelayByResult(result, ActionType.Update, name);

    this.setState({ isRenameDialogOpen: false, isRenameDialogLoading: false });
  }

  private showMessageWithDelayByResult(
    result: ActionResult,
    actionType: ActionType,
    name: string | undefined = undefined
  ): void {
    const configurationName = name ?? this.context.getActiveReportConfiguration()?.name ?? '';
    this.showMessageWithDelay(
      result == ActionResult.Ok,
      this.getMessageByCode(result, actionType, configurationName)
    );
  }

  private showMessageWithDelay(isSuccess: boolean, message: string): void {
    this.setState({
      isMessageVisible: true,
      isMessageSuccess: isSuccess,
      messageText: message,
    });

    if (this.messageTimeoutIdentifier) {
      clearTimeout(this.messageTimeoutIdentifier);
    }

    this.messageTimeoutIdentifier = setTimeout(() => {
      this.setState({
        isMessageVisible: false,
      });
    }, DynamicReport.messageTimeout);
  }

  private hideSaveDialog(): void {
    this.setState({
      isSaveDialogOpen: false,
    });
  }

  private getMessageByCode(
    saveResult: ActionResult,
    actionType: ActionType,
    configurationName: string
  ): string {
    switch (saveResult) {
      case ActionResult.Ok:
        return getTranslation(
          actionType === ActionType.Delete
            ? 'Delete_Report_Configuration_Successfully_Message'
            : 'Save_Report_Configuration_Successfully_Message'
        ).replace('{0}', configurationName);
      case ActionResult.AlreadyExists:
        return getTranslation('Save_Report_Configuration_Already_Exists_Message').replace(
          '{0}',
          configurationName
        );
      default:
        return getTranslation('Error');
    }
  }

  private async cacheDefaultConfiguration(): Promise<void> {
    const slicersValues = await this.getSlicersValues();
    const [fields, gcns, smids] = slicersValues;

    this._defaultConfiguration = {
      id: 'default',
      name: 'default',
      reportId: -1,
      settings: {
        fields,
        gcns,
        smids,
      },
      userIds: [],
      dataAuthRoleIds: [],
      lastModifiedDate: 'now',
    };
  }

  private async hasUnsavedChanges(): Promise<boolean> {
    if (
      this.state.areReportConfigurationsLoading ||
      this.state.isPowerBiLoading ||
      this.state.isPageLoadingFailed ||
      !this.state.isDefaultConfigurationApplyingFinished
    ) {
      return false;
    }

    let currentConfiguration = this.context.getActiveReportConfiguration();
    currentConfiguration ??= this._defaultConfiguration;

    const slicersValues = await this.getSlicersValues();
    const [fields, gcns, smids] = slicersValues;

    return (
      !!currentConfiguration &&
      (!haveSameElements(fields, currentConfiguration.settings.fields) ||
        !haveSameElements(gcns, currentConfiguration.settings.gcns) ||
        !haveSameElements(smids, currentConfiguration.settings.smids))
    );
  }
}

export default withRouter(DynamicReport);
