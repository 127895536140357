import React, { useState } from 'react';
import { Grid, GridColumn, Icon } from 'semantic-ui-react';
import './styles.scss';

type SidebarProps = {
  panel: React.ReactNode;
  title: string;
};

const Sidebar: React.FC<SidebarProps> = ({ children, panel, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={{ display: 'flex' }}>
      <div className="expandable-panel" style={{ width: isExpanded ? '200px' : '40px' }}>
        <div className="state-toggler" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? (
            <div>
              <Grid>
                <Grid.Row>
                  <GridColumn width={12}>
                    <p className="title-label">{title}</p>
                  </GridColumn>
                  <GridColumn width={4}>
                    <Icon name="angle double left" />
                  </GridColumn>
                </Grid.Row>
              </Grid>
            </div>
          ) : (
            <div>
              <Icon name="angle double right" />
              <p className="rotated-title-label">{title}</p>
            </div>
          )}
        </div>
        <div className="sidebar-panel"></div>
        {isExpanded && panel}
      </div>
      <div className="sidebar-content">{children}</div>
    </div>
  );
};

export default Sidebar;
