import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { InsertResponse, UpdateResponse } from '../api/graphql/models/ReportsResponse';
import { ReportTypeApiDto, ReportTypeDto } from '../api/graphql/models/ReportTypeDto';
import { ApolloClientResponse } from '../api/graphql/models/common';
import { INSERT, UPDATE } from '../api/graphql/mutations/ReportMutations';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import { APIFetch } from '../utils/APIHandlers';
import { handleError } from '../utils/errorHandler';

export class ReportTypesService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly endpoint: string;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
    this.endpoint = '/v1/tickets/pet/reports';
  }

  public async getReportTypes(
    arePaginatedExcluded?: boolean
  ): Promise<RestApiResponse<ReportTypeApiDto[]> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<ReportTypeApiDto[]>>('get', PetApi, `${this.endpoint}`, {
        arePaginatedExcluded,
      });
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async insert(report: ReportTypeDto): Promise<ReportTypeDto | undefined | null> {
    const result = await this.apolloClient.mutate<InsertResponse>({
      mutation: INSERT,
      variables: { report },
    });

    return result.data?.insertOneReport;
  }

  public async update(id: number, report: ReportTypeDto): Promise<ApolloClientResponse<UpdateResponse>> {
    return await this.apolloClient.mutate<UpdateResponse>({
      mutation: UPDATE,
      variables: { id, report },
    });
  }
}
