export const removeClones = (insert: string[]): string[] => {
  const set: Set<string> = new Set(insert);

  return Array.from(set);
};

export const getLowerCaseArray = (insert: string[]): string[] => {
  return insert.map((item) => item.toLowerCase());
};

export const buildArrayBySeparator = (insert: string[], separator: string): string[] => {
  let clearValue: string[] = [];

  if (insert.length > 0) {
    let stringValue = '';

    stringValue = insert.join(separator);
    stringValue = stringValue.replace(/\s+/g, '');
    clearValue = stringValue.split(separator);
  }

  return clearValue;
};

export const getValidArrayByReq = (insert: string[], expression: RegExp): string[] => {
  const validValue: string[] = [];

  if (insert.length > 0) {
    insert.forEach((item) => {
      expression.test(String(item)) && validValue.push(item);
    });
  }

  return validValue;
};

export const haveSameElements = <T>(array1: T[], array2: T[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = [...array1].sort(defaultComparator);
  const sortedArray2 = [...array2].sort(defaultComparator);

  return sortedArray1.every((value, index) => value === sortedArray2[index]);
};

function defaultComparator<T>(a: T, b: T): number {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
}
