/* eslint-disable react/jsx-no-bind */

import React from 'react';
import { models, Report, Embed } from 'powerbi-client';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { Dimmer, Icon } from 'semantic-ui-react';
import * as pbi from 'powerbi-client';

import { UserContextProps } from '../../shared/store/userContext';
import { ReactComponent as LoaderImage } from '../../assets/loaderImage.svg';
import { getTranslation } from '../../utils/getTranslation';
import { ReportsConfigsResponse } from '../../models/ReportsAggregate/ReportsConfigs';

import './styles.scss';

export interface BasicReportProps {
  embedUrl: string;
  token: string;
  updateToken: (props: GetReportsConfigProps) => Promise<ReportsConfigsResponse>;
  displayName: string;
  isReportLoaderShown?: boolean;
  reportClassName?: string;
  context: UserContextProps;
}

export interface BasicReportState {
  report?: Report;
  isReportFullScreen: boolean;
  isLoading: boolean;
}

interface GetReportsConfigProps {
  workSpaceId: string;
  environment: string;
  version: string;
}

class BasicReport<P extends BasicReportProps, S extends BasicReportState> extends React.Component<P, S> {
  private readonly powerbi: pbi.service.Service;
  private readonly isReportsExportAccess: boolean;
  private readonly sampleReportConfig: models.IReportEmbedConfiguration;

  constructor(props: P) {
    super(props);

    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );

    const { isReportsExportAccess } = this.props.context;
    this.isReportsExportAccess = isReportsExportAccess;

    this.sampleReportConfig = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      permissions: models.Permissions.Read,
      viewMode: models.ViewMode.View,
      embedUrl: props.embedUrl,
      accessToken: props.token,
      settings: {
        panes: {
          pageNavigation: {
            visible: false,
          },
          filters: {
            expanded: false,
          },
        },
        visualSettings: {
          visualHeaders: [
            {
              settings: { visible: this.isReportsExportAccess },
            },
          ],
        },
        hideErrors: true,
      },
      theme: {
        themeJson: {
          name: 'Table theme',
          background: '#FFFFFF',
          foreground: '#19191A' /*text color*/,
          tableAccent: '#E1E3E6' /*border between header and table's body*/,
          secondLevelElements: '#19191A' /*text current Account*/,
        },
      },
    };

    this.state = {
      report: undefined,
      isReportFullScreen: false,
      isLoading: this.props.isReportLoaderShown ?? true,
    } as S;
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('BasicReport error', error, errorInfo);
  }

  public render(): React.ReactNode {
    return (
      <div
        className={`report-container ${this.props.reportClassName ?? ''} ${
          this.state.isLoading ? 'blur-bg' : ''
        } ${this.state.isReportFullScreen ? 'fullscreen' : ''}`}
      >
        <div className="btn-container">
          {this.isReportsExportAccess && (
            <button className="sticky-btn" type="button" color="blue" onClick={this.printReport.bind(this)}>
              {getTranslation('Reports_Export')}
            </button>
          )}
          <Icon
            name={this.state.isReportFullScreen ? 'compress' : 'expand'}
            onClick={this.fullScreenReportHandler.bind(this)}
          />
        </div>
        {this.state.isLoading && (
          <Dimmer active inverted>
            <LoaderImage />
          </Dimmer>
        )}
        <PowerBIEmbed
          embedConfig={this.sampleReportConfig}
          cssClassName="report-style-class"
          eventHandlers={this.buildEventHandlers()}
          getEmbeddedComponent={(embedObject: Embed) => this.setState({ report: embedObject as Report })}
        />
      </div>
    );
  }

  protected onReportReady(_: Report): Promise<void> {
    // default implementation (no extra handling required in base class)
    return Promise.resolve();
  }

  protected buildEventHandlers(): Map<string, EventHandler> {
    const that = this;

    return new Map([
      [
        'error',
        async function (event: { detail: { message: string } }) {
          if (event.detail.message === 'TokenExpired') {
            const reportsConfig = await that.props.updateToken({
              workSpaceId: that.props.context.workSpaceId,
              environment: that.props.context.environment,
              version: that.props.context.version,
            });

            if (that.state.report && reportsConfig) {
              const report = that.powerbi.get(that.state.report.element);

              await report.setAccessToken(reportsConfig.embedToken.token);

              await report.reload();
            }
          }
        } as unknown as EventHandler,
      ],
      [
        'rendered',
        async function () {
          if (that.state.isLoading) {
            that.setState({ isLoading: false });

            if (that.state.report) {
              await that.onReportReady(that.state.report);
            }
          }
        } as unknown as EventHandler,
      ],
    ]);
  }

  private fullScreenReportHandler(): void {
    const isReportFullScreenCurrently = this.state.isReportFullScreen;

    this.setState({ isReportFullScreen: !isReportFullScreenCurrently });
  }

  private printReport(): void {
    if (this.state.report) {
      const embedReport = this.powerbi.get(this.state.report.element);

      void (embedReport as Report)?.print?.();
    }
  }
}

export default BasicReport;
