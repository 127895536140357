import React, { useContext, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { ReportTypeApiDto } from '../../api/graphql/models/ReportTypeDto';
import { ReportTypesService } from '../../services/ReportTypesService';
import { PetUsersService } from '../../services/PetUsersService';
import { userContext } from '../../shared/store/userContext';
import {
  defaultUserInfoContextValue,
  UserInfoContextProps,
  userInfoContext,
} from '../store/profileUserInfoContext';
import { PetUserApiDTO } from '../../api/graphql/models/Users';
import { RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { hasAnyAccess } from '../../models/FeatureAccessGroupsAggregate/ReportAccessType';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const ProfileUserInfo: React.FC<Props> = ({ children, client }) => {
  const [userReports, setUserReports] = useState<ReportTypeApiDto[]>(defaultUserInfoContextValue.userReports);
  const [userInfo, setUserInfo] = useState<PetUserApiDTO | null>(defaultUserInfoContextValue.userInfo);
  const [isSpinnerShow, setIsSpinnerShow] = useState(defaultUserInfoContextValue.isSpinnerShow);
  const [isErrorShow, setIsErrorShow] = useState(defaultUserInfoContextValue.isErrorShow);
  const { apiBaseUrl } = useContext(userContext);

  const getUserInfo = async () => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const reportTypesService: ReportTypesService = new ReportTypesService(clientApollo);
      const userService: PetUsersService = new PetUsersService(clientApollo, apiBaseUrl);

      const userInfoResponse = await userService.getCurrent();
      const userInfo = (userInfoResponse as RestApiResponse<PetUserApiDTO>)?.result;
      setUserInfo(userInfo);

      if (hasAnyAccess(userInfo?.featureAccessGroup?.reportManagement?.accessType)) {
        const reportTypesResponse = await reportTypesService.getReportTypes();
        const reportTypes = (reportTypesResponse as RestApiResponse<ReportTypeApiDto[]>)?.result;
        if (reportTypes) {
          const availableReports = reportTypes.filter(
            ({ id }) =>
              typeof id == 'number' && userInfo?.featureAccessGroup?.reportManagement?.reports.includes(id)
          );

          setUserReports(availableReports);
        }
      }
    } catch (error) {
      setIsErrorShow(true);
      console.error(error);
    }

    setIsSpinnerShow(false);
  };

  const getUserInfoContext = (): UserInfoContextProps => {
    return {
      ...defaultUserInfoContextValue,
      getUserInfo,
      userReports,
      userInfo,
      isSpinnerShow,
      isErrorShow,
    };
  };

  return <userInfoContext.Provider value={getUserInfoContext()}>{children}</userInfoContext.Provider>;
};

const WithProfileUserInfo = WithApolloClient(ProfileUserInfo);

export { WithProfileUserInfo };
