import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { GroupableDropdownProps, GroupDefinition } from './types';
import './styles.scss';
import { getTranslation } from '../../../utils/getTranslation';

const GroupableDropdown: React.FC<GroupableDropdownProps> = ({
  groups,
  loading,
  disabled,
  onSelectedValueChange,
  selectedValue,
}) => {
  const [searchCreteria, setSearchCreteria] = React.useState<string | undefined>('');
  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const isAnyItemProvided = groups.some((group) => group.items.length > 0);

  const toDropdownGroup = (group: GroupDefinition): JSX.Element[] => {
    const result = [];

    if (group.title) {
      result.push(
        <Dropdown.Header key={group.title} as="h1" data-testid={`groupable-dropdown-header-${group.title}`}>
          <div className="group-header-style">{group.title}</div>
        </Dropdown.Header>
      );
    }

    result.push(
      ...group.items.map((item) => (
        <Dropdown.Item
          key={item.value}
          text={item.title}
          value={item.value}
          active={selectedValue === item.value}
          onClick={(e, { value }) => {
            setIsOpened(false);
            setSearchCreteria('');

            if (onSelectedValueChange) {
              onSelectedValueChange(value as string);
            }
          }}
          data-testid={`groupable-dropdown-item-${item.value}`}
        />
      ))
    );

    return result;
  };

  const getSelectedValueText = () => {
    const group = groups.find((group) => group.items.some((item) => item.value === selectedValue));
    if (group) {
      const item = group.items.find((item) => item.value === selectedValue);
      return item?.title;
    }
    return '';
  };

  const visibleGroups = groups
    .map((group) => {
      return {
        title: group.title,
        items: searchCreteria
          ? group.items.filter((item) =>
              item.title.toLocaleLowerCase().includes(searchCreteria.toLocaleLowerCase())
            )
          : group.items,
      };
    })
    .filter((group) => group.items.length > 0);
  const result = visibleGroups.flatMap((group) => toDropdownGroup(group));
  return (
    <Dropdown
      noResultsMessage={
        isAnyItemProvided ? getTranslation('No_Results_Found') : getTranslation('No_Items_To_Show')
      }
      search={(o) => o}
      fluid
      selection
      loading={loading}
      disabled={disabled}
      open={isOpened}
      selectOnBlur={true}
      onClick={() => setIsOpened(!isOpened)}
      onBlur={() => {
        setSearchCreteria('');
        setIsOpened(false);
      }}
      options={result}
      text={getSelectedValueText()}
      searchQuery={searchCreteria}
      onSearchChange={(e, { searchQuery }) => {
        setIsOpened(true);
        setSearchCreteria(searchQuery);
      }}
      data-testid="groupable-dropdown"
    ></Dropdown>
  );
};

export default GroupableDropdown;
