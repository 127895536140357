import React, { useContext, useEffect } from 'react';
import { Divider, Form, Grid, Header, Message } from 'semantic-ui-react';

import TableLabel from '../../features/TableLabel';
import {
  getAccessTypesConfig,
  getAccessTypesReportConfig,
} from '../../features/AdministrationContainer/FeatureAccessGroups/constants';
import RestrictionFilterValue from '../../features/PetForm/RestrictionFilterValue';
import { getTranslation } from '../../utils/getTranslation';
import { userInfoContext } from '../../shared/store/profileUserInfoContext';
import LabelWrapper from '../../shared/view/LabelWrapper/LabelWrapper';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';
import { hasAnyAccess } from '../../models/FeatureAccessGroupsAggregate/ReportAccessType';

import './styles.scss';

const Profile: React.FC = () => {
  const { userReports, userInfo, isSpinnerShow, isErrorShow, getUserInfo } = useContext(userInfoContext);
  const accessTypesConfig = getAccessTypesConfig();
  const accessTypesReportConfig = getAccessTypesReportConfig();

  useEffect(() => {
    void getUserInfo();
  }, []);

  return (
    <div className="container">
      <div className="auditInfo profile">
        <Header as="h1">{getTranslation('Header_Profile')}</Header>
        {isSpinnerShow ? (
          <FullSizeLoader dataTestId="profile-loader" />
        ) : isErrorShow ? (
          <Message error data-testid="profile-errorContent">
            {getTranslation('Error')}
          </Message>
        ) : (
          <div data-testid="profile-content">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4}>
                  <LabelWrapper label={getTranslation('Profile_UserName')}>
                    <div className="form-header" data-testid="profile-userFullName">
                      {userInfo?.firstName} {userInfo?.lastName}
                    </div>
                  </LabelWrapper>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4}>
                  <LabelWrapper label={getTranslation('AuditInfo_EmailLogin')}>
                    <div className="blueEmail" data-testid="profile-userEmail">
                      {userInfo?.email}
                    </div>
                  </LabelWrapper>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                  <LabelWrapper label={getTranslation('FeatureGroup')}>
                    <div data-testid="profile-featureAccessGroupName">
                      {userInfo?.featureAccessGroup?.groupName}
                    </div>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>

              <Divider />

              <Grid.Row>
                {typeof userInfo?.featureAccessGroup?.userManagement?.accessType === 'number' && (
                  <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4}>
                    <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_UserManagement')}>
                      <div data-testid="profile-featureAccessGroupUserManagementType">
                        <TableLabel
                          accessTypeConfig={
                            accessTypesConfig[userInfo?.featureAccessGroup?.userManagement.accessType]
                          }
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                )}
                {typeof userInfo?.featureAccessGroup?.ticketsManagement?.accessType === 'number' && (
                  <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4}>
                    <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_TicketsManagement')}>
                      <div data-testid="profile-featureAccessGroupTicketsManagementType">
                        <TableLabel
                          accessTypeConfig={
                            accessTypesConfig[userInfo?.featureAccessGroup?.ticketsManagement.accessType]
                          }
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                )}
                {typeof userInfo?.featureAccessGroup?.scheduledReportsManagement?.accessType === 'number' && (
                  <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4}>
                    <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_ExportJobsManagement')}>
                      <div data-testid="profile-featureAccessGroupScheduledReportsManagementType">
                        <TableLabel
                          accessTypeConfig={
                            accessTypesConfig[
                              userInfo?.featureAccessGroup?.scheduledReportsManagement?.accessType
                            ]
                          }
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                )}
                {typeof userInfo?.featureAccessGroup?.reportManagement?.accessType === 'number' && (
                  <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4} widescreen={4}>
                    <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_ReportsPermission')}>
                      <div data-testid="profile-featureAccessGroupReportManagementType">
                        <TableLabel
                          accessTypeConfig={
                            accessTypesReportConfig[
                              userInfo?.featureAccessGroup?.reportManagement?.accessType
                            ]
                          }
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                )}
                {hasAnyAccess(userInfo?.featureAccessGroup?.reportManagement?.accessType) && (
                  <Grid.Column width={16}>
                    <Form.Group>
                      <LabelWrapper label={getTranslation('Reports')}>
                        <div className="values" data-testid="profile-reportsControls">
                          {userReports.map(({ displayName }, i) => (
                            <div className="value-container" key={i}>
                              {displayName}
                            </div>
                          ))}
                        </div>
                      </LabelWrapper>
                    </Form.Group>
                  </Grid.Column>
                )}
              </Grid.Row>
              {userInfo?.dataAuthRole && (
                <Grid.Row data-testid="profile-userDataAuthRoleId">
                  <Grid.Column width={16}>
                    <LabelWrapper label={getTranslation('DataAuthorizationRole')}>
                      <div data-testid="profile-userDataAuthRoleId-roleName">
                        {userInfo.dataAuthRole.roleName}
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <LabelWrapper label={getTranslation('GlobalCustomerNumber')}>
                      <div data-testid="profile-userDataAuthRoleId-gcns">
                        <RestrictionFilterValue
                          restrictionFilter={userInfo.dataAuthRole.gcns}
                          key="gcns"
                          numberDisplayedValues={16}
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <LabelWrapper label={getTranslation('SecurityManagerID')}>
                      <div data-testid="profile-userDataAuthRoleId-smids">
                        <RestrictionFilterValue
                          restrictionFilter={userInfo.dataAuthRole.smids}
                          key="smids"
                          numberDisplayedValues={16}
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <LabelWrapper label={getTranslation('LocalCustomerNumber')}>
                      <div data-testid="profile-userDataAuthRoleId-lcns">
                        <RestrictionFilterValue
                          restrictionFilter={userInfo.dataAuthRole.lcns}
                          key="lcns"
                          numberDisplayedValues={16}
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <LabelWrapper label={getTranslation('PCC')}>
                      <div data-testid="profile-userDataAuthRoleId-pccs">
                        <RestrictionFilterValue
                          restrictionFilter={userInfo.dataAuthRole.pccs}
                          key="pccs"
                          numberDisplayedValues={16}
                        />
                      </div>
                    </LabelWrapper>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
