import { ErrorFetchResponse } from '../api/rest/models/RestApiResponse';
import { API_REQUEST_CODE } from './APIHandlers';
import { getTranslation } from './getTranslation';

const errorConfig = {
  user: {
    regexp: /user/i,
    error: 'CommonEmailError',
  },
  userOrPassword: {
    regexp: /user|password/i,
    error: 'CommonEmailPasswordError',
  },
  duplicate: {
    regexp: /duplicate/i,
    error: 'AlreadyExists',
  },
};

export const getUIErrorMessage = (
  str = '',
  errorKey: 'user' | 'userOrPassword' | 'duplicate',
  additionalText?: string
): string => {
  const { error, regexp } = errorConfig[errorKey];
  return regexp.test(str)
    ? `${additionalText ?? ''} ${getTranslation(error)}`.trim()
    : getTranslation('CommonError');
};

export const getFetchErrorMessage = (e: ErrorFetchResponse): string => {
  return e.response?.data.messages?.join(',') || getTranslation('CommonError');
};

export const getNotAcceptedErrorResponse = (): ErrorFetchResponse => ({
  response: {
    data: {
      code: API_REQUEST_CODE.ACCEPTED,
      status: '',
      messages: [getTranslation('CommonError')],
    },
  },
});

export const getUnauthorizedErrorResponse = (message: string): ErrorFetchResponse => ({
  response: {
    data: {
      code: API_REQUEST_CODE.UNAUTHORIZED,
      status: '',
      messages: [message],
    },
  },
});

export const handleError = (error: any): ErrorFetchResponse => {
  if (error instanceof FetchError) {
    return { response: error.response };
  }

  return {
    response: {
      data: {
        code: API_REQUEST_CODE.INTERNAL_SERVER_ERROR,
        status: '',
      },
    },
  };
};

export class FetchError extends Error {
  public response;

  constructor(e: ErrorFetchResponse) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
    this.response = e.response;
  }
}
