import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_ALL, GET_BY_EMAIL, GET_BY_ID } from '../api/graphql/queries/PetUsersQueries';
import {
  CreatePetUserDTO,
  GetUsersResponse,
  GetUserResponse,
  PetUserDTO,
  UpdatePetUserDTO,
  PetUserApiDTO,
} from '../api/graphql/models/Users';
import { PaginatedResponse } from '../api/graphql/models/common';
import { PetApi } from '../constants/apiParams';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { APIFetch } from '../utils/APIHandlers';
import { SortOrder } from '../utils/viewHelper';
import { ASCENDING_VALUE } from '../utils/constants';
import { handleError } from '../utils/errorHandler';

export class PetUsersService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly endpoint: string;
  private readonly apiBaseUrl: string;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>, apiBaseUrl: string) {
    this.apolloClient = apolloClient;
    this.endpoint = '/v1/tickets/pet/users';
    this.apiBaseUrl = apiBaseUrl;
  }

  public async getAll(
    limit: number,
    offset: number,
    sortBy: keyof PetUserDTO = 'lastName',
    sortOrder: SortOrder = ASCENDING_VALUE
  ): Promise<PaginatedResponse<PetUserDTO>> {
    const result = await this.apolloClient.query<GetUsersResponse>({
      query: GET_ALL,
      variables: { limit, offset, sortBy, sortOrder },
    });

    return result.data.paginatedPetUsers;
  }

  public async getById(id: string): Promise<PetUserDTO> {
    const result = await this.apolloClient.query<GetUserResponse>({
      query: GET_BY_ID,
      variables: { id },
    });

    return result.data.petUser;
  }

  public async getCurrent(): Promise<RestApiResponse<PetUserApiDTO> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<PetUserApiDTO>>('get', PetApi, `${this.endpoint}/current`, {});
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async getByEmail(email: string): Promise<PetUserDTO> {
    const result = await this.apolloClient.query<GetUserResponse>({
      query: GET_BY_EMAIL,
      variables: { email },
    });

    return result.data.petUser;
  }

  public async createNewUser(
    user: CreatePetUserDTO
  ): Promise<RestApiResponse<CreatePetUserDTO> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<CreatePetUserDTO>>('post', PetApi, this.endpoint, {
      body: user,
    });
  }

  public async updateUser(
    user: UpdatePetUserDTO
  ): Promise<RestApiResponse<UpdatePetUserDTO> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<UpdatePetUserDTO>>('put', PetApi, this.endpoint, {
      body: user,
    });
  }

  public async deleteUser(id: string): Promise<RestApiResponse<UpdatePetUserDTO> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<UpdatePetUserDTO>>('del', PetApi, `${this.endpoint}/${id}`, {});
  }
}
